$nat__gradient__background: linear-gradient(80deg, #0c8cdc45 20%, #76ffa45c 57%);
$nat__button__color: #0b1751;
$nat__tab__color__active: #f8f8f8;
$nat__tab__text__color: #344767;
$nat__tab__color: #f8f8f8af;
$nat__button__text__color: #7b809a;
$nat__card__shadow: 0 4px 6px -1px rgba(0, 0, 0, .1),
0 2px 4px -1px rgba(0, 0, 0, .06);
$nat__dropdown__shadow: 0 20px 20px -1px rgba(0, 0, 0, 0.21),
0 1px 4px -1px rgba(0, 0, 0, 0.23);
$nat__table__hover__color: #bde0f5;
$nat__modal__background__color: #fff;
$nat__tree__background__color: aliceblue;

body {
	text-align: center;
	overflow-x: hidden !important;
	overflow-y: hidden !important;

	.nat__dropdown__shadow {
		box-shadow: $nat__dropdown__shadow;
	}

	.nat__card__wrapper {
		background-color: #fff !important;
		box-shadow: $nat__card__shadow;
	}
	.nat__tree__wrapper {
		background-color: $nat__tree__background__color !important;
		box-shadow: $nat__card__shadow;
	}
	.nat__login {
		max-width: 395px;
	}

	.nat__gardient__bg {
		background-image: $nat__gradient__background;
	}

	.default__nat__card__body__height {
		height: 100%;
	}

	.default__nat__form__body__height {
		height: 100%;
		min-height: 100%;
	}

	.nat__button {
		color: #fff;
		background-color: $nat__button__color;
		font-size: 16px;
		border-radius: 1.8rem;

		&:hover {
			color: #fff;
		}
	}

	.nat__button__hover__grey {
		color: $nat__tab__text__color !important;

		&:hover {
			background-color: $nat__tab__color !important;
		}
	}

	.nat__button__focus {
		&:focus {
			color: black;
			background-color: $nat__tab__color;
			box-shadow: none;
		}
	}

	.nat__button__hover {
		background-color: transparent;
		color: $nat__button__text__color;
		font-size: .9rem;
		line-height: 1.667;
		border-radius: 1.8rem;

		&:hover {
			color: #fff;
			background-color: $nat__button__color;
		}
	}

	.nat__card {
		max-width: 100%;

		.nat__card__navbar {
			.nav-tabs {
				flex-wrap: nowrap;
				overflow-x: auto;
				overflow-y: hidden;

				&::-webkit-scrollbar {
					height: 5px;
					background: $nat__tab__color__active;
				}

				&:hover::-webkit-scrollbar-thumb {
					border-radius: 4px;
					background: $nat__button__color;
				}

				.nav-item {
					flex-shrink: 0;
				}

				@media (max-width: 1305px) {
					overflow-x: auto;
				}
			}

			.nat__navbar__tabs {
				.nav-link {
					border-top-left-radius: 1.375rem;
					border-top-right-radius: 1.375rem;
					background-color: $nat__tab__color;

					&:hover {
						border: 1px solid transparent;
						background-color: $nat__button__color;

						.btn {
							color: #fff;
						}
					}
				}

				.btn {
					color: $nat__tab__text__color;
				}

				.nav-link.active,
				.nav-tabs .nav-item.show .nav-link {
					background-color: $nat__tab__color__active;
					border-top-left-radius: 1.375rem;
					border-top-right-radius: 1.375rem;

					.btn {
						color: $nat__tab__text__color;
					}
				}
			}

			.nat__tab__menu {
				background-color: $nat__tab__color__active;
				position: absolute;
				right: 0;
				border-radius: 50% !important;

				.nat__navigation__button {
					border-radius: 50% !important;

					&:hover {
						color: #fff !important;
						background-color: $nat__button__color;
					}
				}

				.dropdown-menu {
					padding: 0 !important;
				}

				.nat__tab__menu__dropdown__list {
					min-height: 50px;
					left: 1.5rem !important;
					max-height: 650px;
					overflow-y: auto;
					overflow-x: hidden;
					z-index: 100000000;
					border: 0;

					.nat__tab__menu__dropdown__item__active {
						background-color: $nat__button__color !important;

						.btn {
							color: white !important;
						}
					}

					.dropdown-item {
						padding: 0.7rem 1.5rem;
						.btn {
							&:active {
								color: white !important;
							}
						}

						&:active {
							background-color: $nat__button__color !important;
						}
					}
				}
			}

			.nat__navbar {
				display: flex;
				max-width: 100%;

				.btn:focus,
				.btn.focus {
					box-shadow: none;
				}
			}

			.nat__navbar__mobile {
				max-width: 100% !important;
			}
		}
	}

	textarea {
		resize: none;
	}

	.nat__page {
		overflow-y: auto;
		overflow-x: hidden;
		background-color: $nat__tab__color__active;

		// min-height: 350px !important;
		.nat__statistics__wrapper {
			height: 0px;

			.nat__statistics__item {
				position: relative;
				-webkit-flex: 0 0 33.333333%;
				flex: 0 0 33.333333%;
			}

			.nat__statistics__item__mobile {
				flex: 0 0 auto !important;
			}
		}

		.nat__page__header {

			.btn:focus,
			.btn.focus {
				box-shadow: none;
			}

			.btn-sm {
				padding: .375rem 1rem;
			}

			.btn:focus,
			.btn.focus {
				box-shadow: none;
			}
		}

		.nat__page__table__wrapper {
			position: relative;
			overflow-y: auto;
			// min-height: 200px;
		}

		.nat__tree__mobile {
			width: 100% !important;
			max-height: 200px;
		}

		.nat__tree {
			position: relative;
			top: 0;
			left: 0;
			bottom: 0;
			width: 30%;
			overflow: auto;
			height: 100%;

			.rstm-tree-item-group {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
			}

			.rstm-tree-item {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: flex;
				align-items: center;
			}

			&::-webkit-scrollbar {
				width: 7px;
				background: transparent;
			}

			&::-webkit-scrollbar-thumb {
				background: transparent;
			}

			&::-webkit-scrollbar-track {
				margin: 2rem;
			}

			&:hover::-webkit-scrollbar-thumb {
				background: #d1d2d3;
			}
		}

		.nat__tree__buttons {
			.nat__tree__buttons__wrapper {
				background-color: rgb(255, 255, 255);
				border-radius: 1.7rem;
				box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.3);
				position: absolute;
				z-index: 1000;
				right: 1.4rem;
				display: flex;
				opacity: 0;
				margin: 0.18rem 0;
			}

			.nat__tree__button {
				padding: 0;
				margin: 0.3rem;
				.btn:focus,
				.btn.focus {
					box-shadow: none;
				}

				outline: none;
			}
		}

		.nat__page__table {
			position: absolute;
			top: 0px;
			left: 0;
			bottom: 0;
			width: 100%;
			
			.rgt-wrapper {
				border: 0;
				min-height: auto;
			}

			.rgt-cell-header {
				background: white;
			}

			.rgt-header-container {
				border: 0;
			}

			.rgt-footer {
				border: 0;
			}

			.rgt-container {
				border: 0;
			}

		}

		.rgt-container-overlay {
			font-size: 15px;
		}
	}

	.nat__table__wrapper__background {
		background-color: rgb(99, 111, 131);
	}

	.nat__table__wrapper {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		flex: 1;
		position: relative;
	}

	.nat__table__wrapper__overflow {
		overflow-y: auto;
	}

	.nat__table__hover {
		.rgt-row-hover {
			cursor: pointer;
			background-color: $nat__table__hover__color;
		}
	}

	.nat__nav__dropdown__list {
		max-height: 200px;
		overflow-y: auto;
		overflow-x: hidden;
		z-index: 100000000;

		.dropdown-item {
			padding: 0.7rem 1.5rem;
			&:active {
				background-color: $nat__button__color !important;
			}
		}
	}

	.nat__dropdown__list {
		min-height: 37px;
		max-height: 200px;
		max-width: 75%;
		overflow-y: auto;
		overflow-x: hidden;
		z-index: 100000000;

		.dropdown-item {
			&:active {
				background-color: $nat__button__color !important;
			}
		}
	}

	.nat__alert {
		.modal-dialog {
			width: 30rem !important;
			height: auto !important;
			margin: 1.75rem auto !important;

		}

		.modal-content {
			border: 0;
			border-radius: 0;
			max-height: 100%;
			background-color: $nat__modal__background__color;
			border-top-left-radius: 2rem !important;
			border-top-right-radius: 2rem !important;
			border-bottom-right-radius: 2rem !important;
			border-bottom-left-radius: 2rem !important;
		}

		.nat__alert__body {
			color: #344767;
		}
	}

	.text__decoration__underline {
		text-decoration: underline;
	}

	.nat__nav__menu {
		.modal-dialog {
			max-width: 100% !important;
			margin: 0;
			padding: 0;
			height: 100%;
		}

		.modal-content {
			height: 100%;
			border: 0;
			border-radius: 0;
			background: $nat__tab__color__active;
		}

		.nat__nav__menu__header {
			border: 0;
			border-radius: 0;

			.close {
				outline: none;
			}
		}

		.nat__nav__menu__body__list__group__wrapper {
			overflow-x: auto;
			overflow-y: hidden;
		}

		.nat__nav__menu__body__list__group {
			max-height: 100%;
			min-width: 250px !important;
			border-radius: 1.75rem !important;
			overflow-y: auto;
			.list-group-item {
				color: black !important;
				border-radius: 1.75rem !important;
			}

			.list-group-item-action:hover,
			.list-group-item-action:focus,
			.list-group-item.active {
				background-color: $nat__button__color !important;
				color: white !important;
				box-shadow: $nat__card__shadow;
				border-radius: 1.75rem !important;
			}
		}
	}

	.nat__selection__filters {
		.modal-dialog {
			max-width: 30% !important;
			margin: 0 0 0 auto !important;
			padding-top: 2rem !important;
			padding-bottom: 2rem !important;
			padding-right: 2rem !important;
			height: 100%;
			transform: none !important;
		}

		.modal {
			z-index: 10050 !important;
		}

		.modal-backdrop {
			z-index: 10040 !important;
		}
	}

	.nat__filter__menu {
		.modal {
			overflow-x: hidden !important;
		}

		.dropdown-menu {
			width: 60%;
		}

		.modal__dialog_45 {
			.modal-dialog {
				max-width: 45% !important;
			}
		}

		.modal__dialog_65 {
			.modal-dialog {
				max-width: 65% !important;
			}
		}

		.modal__dialog_100 {
			.modal-dialog {
				max-width: 100% !important;
				padding-left: 1rem;
			}
		}

		.modal-dialog {
			max-width: 25%;
			margin: 0 0 0 auto;
			padding-top: 5.4rem;
			padding-bottom: 1rem;
			padding-right: 1rem;
			height: 100%;
			transform: none !important;
		}

		.modal-content {
			border: 0;
			border-top-left-radius: 2.75rem !important;
			border-top-right-radius: 2.75rem !important;
			border-bottom-right-radius: 2.75rem !important;
			border-bottom-left-radius: 2.75rem !important;
			background: $nat__tab__color__active;
			height: 100%;
		}

		.modal-backdrop {
			background-color: #00001567;
		}

		.nat__filter__menu__header {
			border: 0;

			.close {
				outline: none;
			}
		}

		.nat__badge {
			background-color: $nat__button__color;
		}

		.nat__filter__menu__body {
			color: black;
			max-height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
			display: flex;
			flex-direction: column;
		}
	}

	.flex__column {
		flex-direction: column;
	}

	.font__size__16 {
		font-size: 16px;
	}

	.enableOverflowY {
		overflow-y: auto !important;
	}

	.disableOverflowY {
		overflow-y: hidden !important;
	}

	.disableOverflowX {
		overflow-x: hidden !important;
	}

	.nat__form {
		background-color: $nat__tab__color__active;
		border: 0;
		overflow-y: auto;
		overflow-x: hidden;
		max-height: 150px;

		.nat__form__header {
			background-color: transparent !important;
			border: 0 !important;
			outline: none;
			padding: 0;
		}

		.nat__form__control__buttons {
			// position: sticky;
			// top: 1.5rem;
			// z-index: 1005;

			.btn:focus,
			.btn.focus {
				box-shadow: none;
			}

			.btn-sm {
				padding: .375rem 1rem;
			}
		}
	}

	.nat__table__control__buttons {

		.btn:focus,
		.btn.focus {
			box-shadow: none;
		}

		.btn-sm {
			padding: .375rem 1rem;
		}
	}

	.nat__form__tabs__mobile {
		.nav {
			max-width: 100% !important;
		}
	}

	.nat__form__tabs-1 {
		.nav {
			max-width: 15.66666666% !important;
		}
	}

	.nat__form__tabs-2 {
		.nav {
			max-width: 31.333333% !important;
		}
	}

	.nat__form__tabs {
		.tab-content {
			height: 100%;
			display: flex;
			flex-direction: column;
			flex: 1;
			position: relative;
		}

		.nav {
			max-width: 50%;
		}

		.nav-item {
			flex: 1 1 auto;
		}

		.nav-tabs {
			border: 0;
			background-color: #ececec;
			border-radius: 1.75rem;
			margin-right: auto;
		}

		.nav-link {
			border-radius: 1.5rem;
			border: 0;
			color: #344767;
			width: 100%;

			&:hover {
				background-color: $nat__button__color;
				color: white;
			}
		}

		.nav-link.active {
			background-color: #f8f8f8 !important;
			color: #344767;
			box-shadow: $nat__card__shadow;
		}
	}

	.nat__navigation {
		.nav {
			overflow-x: auto;
			flex-wrap: nowrap;
		}

		.nav-tabs {
			border: 0;
			background-color: #ececec;
			border-radius: 1.75rem;
			margin-right: auto;
		}

		.nav-link {
			border-radius: 1.5rem;
			border: 0;
			color: #344767;
			width: 100%;

			&:hover {
				background-color: $nat__button__color;
				color: white;
			}
		}

		.nav-link.active {
			background-color: #f8f8f8 !important;
			color: #344767;
			box-shadow: $nat__card__shadow;
		}
	}

	.nat__form__table__wrapper {
		position: relative;
		background-color: #fff !important;
		border-top-left-radius: 2.75rem !important;
		border-top-right-radius: 2.75rem !important;
		border-bottom-right-radius: 2.75rem !important;
		border-bottom-left-radius: 2.75rem !important;
		box-shadow: $nat__card__shadow;
		min-height: 300px;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 6px;
			background: transparent;
		}

		&::-webkit-scrollbar-track {
			border-radius: 4px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background: transparent;
		}

		&:hover::-webkit-scrollbar-thumb {
			background: $nat__button__color;
		}
	}

	.nat__form__table {
		position: absolute;
		top: 0px;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;

		.col-lg-12 {
			padding-top: 1rem;
			padding-bottom: 1rem;
			padding-right: 2.75rem;
			padding-left: 2.75rem;
		}

		textarea {
			padding-top: 1rem;
			padding-bottom: 1rem;
			padding-right: 2.75rem;
			padding-left: 2.75rem;
		}

		.rgt-wrapper {
			border: 0;
			min-height: auto;
		}

		.rgt-cell-header {
			background: white;
		}

		.rgt-header-container {
			border: 0;
		}

		.rgt-footer {
			border: 0;
		}

		.rgt-container {
			border: 0;
		}

	}

	.rgt-container-overlay {
		font-size: 15px;
	}

	.nat__spinner__absolute {
		margin: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-right: -50%;
		transform: translate(-50%, -50%);
		z-index: 1049 !important;
	}
	.spinner {
		margin: 0;
		color: $nat__button__color;
	}

	.form-control.is-invalid {
		border-color: transparent !important;

		&::-webkit-input-placeholder {
			color: #e55353 !important;
		}

		&::-moz-placeholder {
			color: #e55353 !important;
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: transparent;
	}

	.form-control {
		color: #000000;
		font-size: 16px;
		font-weight: normal;
		background-color: transparent;
		border-color: transparent;
		text-align: right;
		border-radius: 1.7rem;

		&:focus {
			outline: none !important;
			box-shadow: inset 0 0 0 2px #bde0f5;
			background-color: rgb(255, 255, 255);
		}
	}

	.nat__login__input {
		flex: 1 1 100%;
	}
	.nat__input__wrapper:hover .nat__form__group {
		box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.3);
		background-color: rgb(255, 255, 255);
		border-radius: 1.7rem;
	}
	.nat__input:hover .nat__form__group {
		box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.3);
		background-color: rgb(255, 255, 255);
		border-radius: 1.7rem;
	}
	.nat__input:hover .nat__form__button {
		border-radius: 1.7rem;
	}
	.nat__form__group {
		margin-top: 0.1rem;
		margin-bottom: 0.1rem;

		&:focus-within {
			box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.3);
			background-color: rgb(255, 255, 255);
			border-radius: 1.7rem;
		}
	}
	.nat__form__button {
		border-radius: 1.7rem;
		&:focus-within {
			border-radius: 1.7rem;
		}
	}
	.rgt-cell {
		color: #000000;
		min-height: 34px;
	}

	.nat__table__info__icon__height {
		max-height: 34px;
	}

	.rstm-toggle-icon {
		display: inline-block;
	}

	.rstm-toggle-icon-symbol {
		width: 2rem;
		height: auto;
		text-align: center;
		line-height: auto;
	}

	.rstm-tree-item-group {
		list-style-type: none;
		padding-left: 0;
		text-align: left;
		width: 100%;
		background-color: transparent;
	}

	.rstm-tree-item {
		padding: 0.3rem 0rem;
		min-height: 34px;
		max-height: 34px;
		cursor: pointer;
		color: #333;
		background: none;
		box-shadow: none;
		z-index: unset;
		position: relative;
		display: flex;
		align-items: center;
		// margin-right: 1.2rem;
		&:hover {
			color: #333;
			background-color: $nat__table__hover__color;
			border-radius: 1.7rem;
		}
	}

	.rstm-tree-item--active {
		color: #333;
		background-color: $nat__table__hover__color !important;
		border-bottom: none;
		border-radius: 1.7rem;
	}

	.rstm-tree-item--focused {
		box-shadow: none;
		z-index: 999;
	}

	.rstm-search {
		padding: 1rem 1.5rem;
		border: none;
		width: 100%;
	}

	.rgt-cell-inner {
		text-align: left;
	}

	.editButton {
		background: #f3f3f3;
		outline: none;
		cursor: pointer;
		padding: 4px;
		display: inline-flex;
		border: none;
		border-radius: 50%;
		box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.3);
		background-color: $nat__button__color;
	}

	.cancelButton {
		background: #f3f3f3;
		outline: none;
		cursor: pointer;
		margin-right: 10px;
		padding: 2px;
		display: inline-flex;
		border: none;
		border-radius: 50%;
		box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.3);
		background-color: $nat__button__color;
	}

	.saveButton {
		background: #f3f3f3;
		outline: none;
		cursor: pointer;
		padding: 2px;
		display: inline-flex;
		border: none;
		border-radius: 50%;
		box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.3);
		background-color: $nat__button__color;
	}

	.nat__modal__fullscreen {
		.modal-dialog {
			max-width: 100% !important;
			margin: 0;
			padding: 2rem;
			height: 100%;
		}

	}

	.nat__modal__product__selection__mobile {
		.modal-dialog {
			min-height: 1220px;
			overflow-y: auto;
		}

		.nat__modal__table__wrapper {
			min-height: 300px;
			// max-height: 300px;
		}

		.nat__modal__tree {
			min-height: 300px;
			max-height: 300px;
		}
		.nat__modal__tree__fullscreen {
			min-height: 100% !important;
			max-height: 100% !important;
		}
	}

	.nat__shopping__cart__modal__mobile {
		.modal-dialog {
			min-height: auto !important;
			height: auto !important;
			padding: 0 !important;
		}
	}

	.nat__shopping__cart__modal {
		.modal-dialog {
			overflow-y: auto !important;
			margin: auto !important;
			padding: 2rem 0 !important;
			height: auto !important;
		}

		.modal-content {
			height: auto !important;
			border: 0;
			background: $nat__tab__color__active;
		}
	}

	.nat__modal__mobile {
		.modal-dialog {
			min-height: 650px;
			overflow-y: auto;
		}

		.nat__modal__table__wrapper {
			// min-height: 100%;
			// height: 100%;
			// max-height: 361px;
		}

		.nat__modal__tree {
			min-height: 361px;
			max-height: 361px;
		}
		.nat__modal__tree__fullscreen {
			min-height: 100% !important;
			max-height: 100% !important;
		}
	}

	.nat__modal__tree__mobile {
		.nat__modal__tree {
			width: 100% !important;
		}
	}

	.nat__modal {
		.nat__set__manager__modal__header {
			display: block;
		}

		.modal-dialog {
			margin: auto;
			padding: 2rem;
			height: 100%;
		}

		.nat__modal__header {
			.search__height {
				max-height: 38px;
			}

			.btn:focus,
			.btn.focus {
				box-shadow: none;
			}

			.btn-sm {
				padding: .375rem 1rem;
			}

			.btn:focus,
			.btn.focus {
				box-shadow: none;
			}
		}

		.nat__modal__table__wrapper {
			position: relative;
			overflow-y: auto;
			height: 100%;
		}

		.nat__modal__shopping__cart__wrapper {
			position: relative;
			height: 100%;
		}

		.nat__modal__table_1,
		.nat__modal__table_2 {
			position: absolute;
			top: 0px;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;

			.rgt-wrapper {
				border: 0;
				// padding: 1rem 0.7rem;
				min-height: auto;
			}

			.rgt-cell-header-inner-not-pinned-right {
				// border-right: 0;
			}

			.rgt-header-container {
				border: 0;
			}

			.rgt-footer {
				border: 0;
			}

			.rgt-container {
				border: 0;
			}

		}

		.nat__modal__table__mobile {
			top: 25px;
		}

		.nat__modal__table__mobile_2 {
			top: 0px;
		}

		.nat__modal__tree {
			position: relative;
			top: 0;
			left: 0;
			bottom: 0;
			width: 30%;
			overflow: auto;
			height: 100%;

			.rstm-tree-item-group {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
			}

			.rstm-tree-item {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: flex;
				align-items: center;
			}

			&::-webkit-scrollbar {
				width: 7px;
				background: transparent;
			}

			&::-webkit-scrollbar-thumb {
				background: transparent;
			}

			&::-webkit-scrollbar-track {
				margin: 2rem;
			}

			&:hover::-webkit-scrollbar-thumb {
				background: #d1d2d3;
			}
		}

		.modal-content {
			height: 100%;
			border: 0;
			border-top-left-radius: 2.75rem !important;
			border-top-right-radius: 2.75rem !important;
			border-bottom-right-radius: 2.75rem !important;
			border-bottom-left-radius: 2.75rem !important;
			background: $nat__tab__color__active;
		}

		.nat__subscription__info__wrapper {
			.nat__subscription__info {
				flex-basis: 50%;
			}

			.nat__subscription__option__readonly {
				min-height: 31px;
			}

			.nat__subscription__payment {
				background: $nat__tab__color__active;

				.nat__subscription__payment__button {
					flex: 1 1 50%;
				}
			}

			.nat__shopping__cart__items {
				border-radius: 0 !important;

				li {
					border-left: 0 !important;
					border-right: 0 !important;
					min-height: 41.8px;
				}

				.list__header__item {
					border-top: 0 !important;
				}

				.nat__shopping__cart__items__amount {
					flex: 0 0 auto;
				}

			}
		}

		.nat__partner__subscription__info__wrapper {
			overflow-y: auto;

			.nat__partner__subscription__info__item {
				position: relative;
				-webkit-flex: 0 0 50%;
				flex: 0 0 50%;

				.nat__partner__subscription__info__item__wrapper {
					height: 100%;

					.nat__partner__subscription__info__item__wrapper__text {
						flex-basis: 33.3333333333%;
					}
				}
			}

			.nat__partner__subscription__info__item__mobile {
				flex: 0 0 auto !important;
			}
		}
	}

	.chip {
		.chip__font__size {
			font-size: 85%;
		}

		.btn:focus,
		.btn.focus {
			box-shadow: none;
		}

		outline: none;

		.chip__close__button {
			&:hover {
				background-color: transparent !important;
			}
		}
	}

	.control__panel__button {
		max-height: 34px;
	}
}

.nat__selection__total {
	background: transparent;
	transition: font-size 1s;
}

.public-DraftStyleDefault-ltr {
	font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.quill {
	height: 90%;

	.ql-toolbar {
		border-top: 0 !important;
		border-left: 0 !important;
		border-right: 0 !important;
	}

	.ql-container.ql-snow {
		border-bottom: 0;
		border-left: 0;
		border-right: 0;
	}

	.ql-editor {
		&::-webkit-scrollbar {
			width: 3px;
			background: rgb(255, 255, 255);
		}

		&::-webkit-scrollbar-track {
			border-radius: 4px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background: rgb(255, 255, 255);
		}

		&:hover::-webkit-scrollbar-thumb {
			background: $nat__button__color;
		}
	}
}

.chips__input__field {
	border: 0;
}

.nat__product__img {
	width: 100%;
	height: 100%;
	max-height: 145px;
	max-width: 420px;
	object-fit: contain;
}

.control__buttons__padding {
	padding-left: 0.75rem;
}

.ml-6 {
	margin-left: 4rem;
}

.c-switch-3d-dark .c-switch-input:checked+.c-switch-slider {
	background-color: #42424a;
}

.c-switch[class*="-3d"] .c-switch-slider {
	background-color: #ced4da;
}

.checkbox__width_0 {
	.checkbox {
		width: 0
	}
}

.pl__4_5 {
	padding-left: 1.9rem;
}


.mr__4_8 {
	margin-right: 2.8rem !important;
}

.mr__4_5 {
	margin-right: 2rem;
}

.mr__1_5 {
	margin-right: -0.2rem !important;
}

.mr__3 {
	margin-right: 0.8rem;
}

.ml__3 {
	margin-left: 0.8rem;
}

.nat__hover {
	opacity: 1 !important;
}

.nat__visible {
	display: flex !important;
}

.nat__right {
	right: -4.5rem !important;
}

.nat__right_1 {
	right: -6.5rem !important;
}

.nat__dropdown__without__label {
	.dropdown-menu {
		left: 0rem !important;
	}

}

.nat__dropdown {
	.dropdown-menu {
		right: 2rem;
		top: 2.26rem;
	}

	.nat__dropdown__menu {
		min-width: 200px;
		overflow-y: auto;
		overflow-x: hidden;
	}
}

.nat__dropdown__nav__mobile__menu {
	.dropdown-menu.show {
		flex-wrap: wrap;
	}
}

.nat__dropdown__nav__menu {
	.nat__dropdown__menu__background {
		background: $nat__tab__color__active;
	}

	.nat__dropdown__menu {
		.dropdown-item {
			min-width: auto !important;
		}
	}

	.dropdown-menu.show {
		display: flex;
	}

}

.nat__dropdown__flex {
	.nat__dropdown__menu {
		min-width: 200px;
		overflow-y: auto;
		overflow-x: hidden;

		.dropdown-item {
			min-width: auto !important;
		}
	}

	.dropdown-menu.show {
		display: flex;
		flex-direction: column;
	}

	.nat__dropdown__options {
		min-width: 290px;
	}
}

.nat__table__input__margin {
	margin-right: 3.3rem !important;
}

.nat__input__buttons__mobile {
	position: relative !important;
	background-color: transparent !important;
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
	right: 0 !important;
	top: 1px !important;
	opacity: 1 !important;
}

.nat__input__buttons {
	position: absolute;
	z-index: 100;
	right: -2.5rem;
	top: 0;
	// opacity: 0;
	height: 100%;
	display: flex !important;
	background-color: rgb(255, 255, 255);
	border-radius: 1.7rem;
	box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.3);

	.btn:focus {
		box-shadow: none;
	}

	.nat__dropdown__toggle {
		height: 18px;
		width: 16px;
	}

	.nat__input__button {
		border-radius: 1.7rem;
		background-color: transparent;
		display: flex;
		align-items: center;
		padding: 0;
		margin: 0.5rem;

		.c-icon {
			margin: 0;
		}
	}
}

.nat__tab {
	display: none;
}

.cursor__pointer {
	cursor: pointer;
}

.invalid__feedback__position {
	position: absolute;
	top: 1.8rem;
}

.rgt-search-label {
	margin-bottom: 0 !important;
}

.toaster .toast {
	border: 0;
	background-color: $nat__modal__background__color;
	border-top-left-radius: 2rem !important;
	border-top-right-radius: 2rem !important;
	border-bottom-right-radius: 2rem !important;
	border-bottom-left-radius: 2rem !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px $nat__tab__color__active inset !important;
}

.nat__dropdown__white__scrollbar {
	&::-webkit-scrollbar {
		width: 3px !important;
		background: white !important;
	}

	&::-webkit-scrollbar-track {
		border-radius: 4px !important;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px !important;
		background: white !important;
	}

	&:hover::-webkit-scrollbar-thumb {
		background: $nat__button__color !important;
	}
}

.nat__dropdown__transparent__scrollbar {
	&::-webkit-scrollbar {
		width: 3px !important;
	}

	&::-webkit-scrollbar-track {
		margin: 1.9rem 0 !important;
		border-top-right-radius: 2.75rem !important;
		border-bottom-right-radius: 2.75rem !important;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px !important;
		margin: 3rem 0 !important;
	}

	&:hover::-webkit-scrollbar-thumb {
		margin: 3rem 0 !important;
		background: $nat__button__color !important;
	}
}

.nat__scrollbar {
	&::-webkit-scrollbar {
		width: 6px;
		background: transparent;
	}

	&::-webkit-scrollbar-track {
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background: transparent;
	}

	&:hover::-webkit-scrollbar-thumb {
		background: $nat__button__color;
	}
}

.del {
	position: relative;
	text-decoration: none;
}

.del:before {
	content: "";
	border-bottom: 2px solid $nat__button__color;
	position: absolute;
	width: 100%;
	height: 50%;
	transform: rotate(-12deg);
}

.nat__borders__2-75 {
	border-top-left-radius: 2.75rem !important;
	border-top-right-radius: 2.75rem !important;
	border-bottom-right-radius: 2.75rem !important;
	border-bottom-left-radius: 2.75rem !important;
}
.nat__borders__left__2-75 {
	border-top-left-radius: 2.75rem !important;
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 2.75rem !important;
}
.nat__borders__right__2-75 {
	border-top-left-radius: 0 !important;
	border-top-right-radius: 2.75rem !important;
	border-bottom-right-radius: 2.75rem !important;
	border-bottom-left-radius: 0 !important;
}
.nat__input__label__mobile_100 {
	width: 100px;
}
.nat__input__label__mobile_70 {
	width: 70px;
}
.nat__tree__item__icon__height {
	max-height: 24px !important;
}
#dragFileElement {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	border: 1px black;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	z-index: 10000000;
}
#fileUpload.dragActive {
	opacity: 0.5;
}
#checkbox {
	accent-color: $nat__button__color;
}
.custom-control-input:checked ~ .custom-control-label::before {
	background-color: $nat__button__color;
	border-color: $nat__button__color;
}